import { useState } from 'react'
import {useQuery} from 'react-query'
// Components
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Badge from '@material-ui/core/Badge';
// Styles
// import {Wrapper } from './App.styles';

import image01 from './images/livin.gif'
import image02 from './images/lilface.png'




const App = () => {

  const d = new Date();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const day = days[ d.getDay()];


  return (

    
    <div className="App">

    
    <img src={image01} />
    <div className="App">

    </div>
    <h1>Hi Honey, Happy {day}! <img src={image02}></img></h1>
    </div>
    
    );
}

//  <img src={logo} alt="something..."></img>
export default App;
